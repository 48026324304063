import React from 'react';
import { ProductCard } from '../CategoryPage/ProductCard';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import MaxWidth from '../Layout/MaxWidth';
import { GoldBar } from '../Layout/GoldBar';
import { theme } from '../Theme';

const ShopTheLookWrapper = styled(MaxWidth)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  &.SMALL {
    max-width: 400px;
  }
  &.MEDIUM {
    max-width: 800px;
  }
  &.LARGE {
    max-width: 1200px;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div {
    width: 100%;
    margin: 0 10px;
    ${theme.below.lg} {
      min-width: 80%;
      margin-bottom: 20px;
    }
  }
  a {
    margin: 0 !important;
  }
  ${theme.below.lg} {
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding: 0 10px;
  }
`;

const Header = styled('h3')`
  font-size: 16px;
  line-height: 23px;
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.darkgrey};
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const EmptyItem = styled('div')`
  height: 100%;
`;

export const ShopTheLookSingleProductItem = ({ product }) => {
  if (!product?.value || !product) return <EmptyItem />;

  return (
    <>
      <ProductCard
        key={product.articleNumber}
        product={product.value}
        as="div"
      />
    </>
  );
};

export const ShopTheLookSingleProduct = ({ title, children, size }) => {
  return (
    <ShopTheLookWrapper className={cx(size?.value)}>
      {title?.value && (
        <GoldBar>
          <Header>{title.value}</Header>
        </GoldBar>
      )}
      <Wrapper>{children}</Wrapper>
    </ShopTheLookWrapper>
  );
};
