import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';

const UspsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;

  > div {
    display: flex;
    margin-right: 10px;
    margin-bottom: 3px;
    font-family: ${theme.fonts.primary};
    font-size: 9px;
    line-height: 13px;
    letter-spacing: 0.03em;

    &:last-of-type {
      margin-right: 0;
    }

    svg {
      width: 10px;
      height: 10px;
      margin-right: 2px;
    }
  }
`;

export const Usps = ({ children }) => {
  return (
    <UspsWrapper>
      {children.map((child, index) => (
        <div key={'usp-' + index} className={'usp'}>
          {child}
        </div>
      ))}
    </UspsWrapper>
  );
};
