import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import { setVariableFromProp } from '../../utils/HelpFunctions';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import { Button } from './Button';
import { ButtonRow, ContentWrapper, HeroWrapper } from './Hero';
import { Text } from './Text';
import { useInView } from 'react-intersection-observer';

const BoxRowWrapper = styled(MaxWidth)`
  display: grid;
  grid-template-columns: ${props => props.templatecolumns};
  background-color: white;

  &.SMALL {
    grid-column-gap: 10px;
  }

  &.MEDIUM {
    grid-column-gap: 20px;
  }

  &.LARGE {
    grid-column-gap: 30px;
  }

  ${theme.below.lg} {
    grid-template-columns: unset;
    grid-column-gap: unset;

    &.SMALL {
      grid-row-gap: 10px;
    }

    &.MEDIUM {
      grid-row-gap: 20px;
    }

    &.LARGE {
      grid-row-gap: 30px;
    }
  }

  .text-component {
    > * {
      line-height: 1.4;
      font-weight: 700;
    }
  }
`;

export const BoxRow = ({ columnGap, children }) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);
  const colGap = setVariableFromProp(columnGap);

  const templateColumns = () => {
    let cssValue = '';
    children.forEach(c => {
      cssValue += 'auto ';
    });
    return cssValue;
  };

  return (
    <BoxRowWrapper
      className={colGap}
      templatecolumns={templateColumns()}
      ref={ref}
    >
      {children}
    </BoxRowWrapper>
  );
};

export const BoxRowItem = ({
  imageUrl,
  text,
  title,
  btnOneTheme,
  btnOneText,
  btnOneLink,
  btnTwoTheme,
  btnTwoText,
  btnTwoLink
}) => {
  const imageSizes = [1 / 4, 1 / 2, 1];
  const content = () => {
    return (
      <ContentWrapper>
        {text?.value && <Text shadow={true} size={'TEXT'} text={text} />}
        {title?.value && <Text shadow={true} size={'TITLE'} text={title} />}
        <ButtonRow className={'button-row'}>
          {btnOneText.value && (
            <Button theme={btnOneTheme} text={btnOneText} link={btnOneLink} />
          )}
          {btnTwoText.value && (
            <Button theme={btnTwoTheme} text={btnTwoText} link={btnTwoLink} />
          )}
        </ButtonRow>
      </ContentWrapper>
    );
  };
  return (
    <HeroWrapper>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <Image
              cover={true}
              aspect={'63:60'}
              src={imageUrl.value}
              sizes={imageSizes}
            >
              {content()}
            </Image>
          ) : (
            <Image
              cover={true}
              aspect={'336:424'}
              src={imageUrl.value}
              sizes={imageSizes}
            >
              {content()}
            </Image>
          )
        }
      </Above>
    </HeroWrapper>
  );
};
