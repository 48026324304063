import * as React from 'react';

export const CustomerContext = React.createContext();

export function CustomerProvider({ children }) {
  const [discount, setDiscount] = React.useState(null);
  const value = { discount, setDiscount };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.customerDiscount = discount;
    }
  }, [discount]);

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
}
