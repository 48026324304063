import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const GoldDotContainer = styled('div')`
  margin-left: 15px;
  ${theme.below.lg} {
    margin-left: 20px;
  }
`;

const GoldDotWrapper = styled('div')`
  position: relative;
  &:before {
    height: 5px;
    width: 5px;
    position: absolute;
    bottom: 50%;
    left: -10px;
    transform: translateY(50%);
    background: ${theme.colors.gold};
    content: '';
    border-radius: 5px;
    ${theme.below.lg} {
      left: -15px;
    }
  }
`;
export const GoldDot = ({ children }) => {
  return (
    <GoldDotContainer>
      <GoldDotWrapper className={'gold-dot'}>{children}</GoldDotWrapper>
    </GoldDotContainer>
  );
};
