import React from 'react';
import { styled } from 'linaria/react';
import { ProductCard } from '../CategoryPage/ProductCard';
import { SliderWithButtons } from '../ui/SharedSlider';
import { theme } from '../Theme';
import { RowTitle } from './RowTitle';

const Wrapper = styled('div')`
  h2 {
    margin-bottom: 40px;
  }
`;

const JournalProductRowWrapper = styled('div')`
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: flex-start;

  &.fullwidth {
    max-width: 100%;
    padding: 0 10px;
  }

  ${theme.below.lg} {
    max-width: 100%;
    padding: 0 10px;
  }
`;

const JournalProductSliderWrapper = styled('div')`
  width: 100%;

  .shared-slider-wrapper {
    margin-top: 0;
    margin-left: 0;
    margin-right: -10px;
  }

  &.fullwidth-slider {
    width: 96%;
    margin: auto;
    ${theme.below.lg} {
      width: 100%;
    }
  }
`;

const JournalProductNosliderWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .product-card {
    width: 100%;
    max-width: 25%;

    ${theme.below.lg} {
      max-width: 50%;
    }
  }
`;

const EmptyItem = styled('div')`
  height: 100%;
`;

export const JournalProductRowItem = ({ product }) => {
  if (!product?.value || !product) return <EmptyItem />;

  return (
    <ProductCard product={product?.value ?? product} as="div"></ProductCard>
  );
};

export const JournalProductRow = ({ children, title, fullWidth }) => {
  return (
    <Wrapper>
      {title?.value && <RowTitle title={title?.value} />}
      <JournalProductRowWrapper className={fullWidth?.value && 'fullwidth'}>
        {children?.length > 4 ? (
          <JournalProductSliderWrapper
            className={fullWidth?.value && 'fullwidth-slider'}
          >
            <SliderWithButtons
              desktopSlides={4}
              mobileSlides={2}
              arrowTopPosition={'38%'}
            >
              {children}
            </SliderWithButtons>
          </JournalProductSliderWrapper>
        ) : (
          <JournalProductNosliderWrapper>
            {children}
          </JournalProductNosliderWrapper>
        )}
      </JournalProductRowWrapper>
    </Wrapper>
  );
};
