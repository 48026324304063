import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const GoldBarContainer = styled('div')`
  margin-bottom: 15px;
`;

const GoldBarWrapper = styled('div')`
  position: relative;
  &:after {
    height: 2px;
    width: 35px;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: ${theme.colors.gold};
    content: '';
  }
`;
export const GoldBar = ({ children }) => {
  return (
    <GoldBarContainer>
      <GoldBarWrapper className={'gold-bar'}>{children}</GoldBarWrapper>
    </GoldBarContainer>
  );
};
