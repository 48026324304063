import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from '../../../Theme';

const Wrapper = styled('div')`
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
`;

const Heading = styled('h2')`
  font-family: ${theme.fonts.primary};
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 24px;
  text-decoration: none;
  text-transform: uppercase;
  color: ${theme.colors.darkgrey} !important;
  a:hover {
    padding: 0 !important;
    color: ${theme.colors.gold};
  }
  a {
    padding: 0 !important;
    text-decoration: none;
  }
`;

const SubCategoriesWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledCategoryLink = styled(CategoryLink)`
  text-decoration: none;
  text-transform: capitalize !important;
  letter-spacing: 0.03em;
  color: ${theme.colors.darkgrey} !important;
  opacity: 0.9;
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 20px !important;
  &:hover {
    color: ${theme.colors.black} !important;
    opacity: 1;
  }
`;

const SubMenuSection = ({
  heading,
  categories,
  onMouseOver,
  onSelect = () => {}
}) => {
  return (
    <Wrapper onMouseOver={onMouseOver}>
      {heading && heading?.primaryRoute ? (
        <Heading>
          <CategoryLink category={heading} onClick={onSelect}>
            {heading.name}
          </CategoryLink>
        </Heading>
      ) : (
        heading && <Heading>{heading.name}</Heading>
      )}

      <SubCategoriesWrapper>
        {categories?.map(category => {
          return (
            <StyledCategoryLink
              onClick={onSelect}
              category={category}
              key={category?.id}
            >
              {category?.name}
            </StyledCategoryLink>
          );
        })}
      </SubCategoriesWrapper>
    </Wrapper>
  );
};

export default SubMenuSection;
