import React, { createContext, useState } from 'react';
import { theme } from '../Theme';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { ReactComponent as Carrot } from '../../svg/Caret.svg';

const SliderWrapper = styled('div')`
  margin-top: 0.7rem;

  ${theme.below.lg} {
    .slick-list {
      width: 140%;
      overflow: visible;
    }
    overflow: hidden;
    /* margin-left: -5px; */
    margin-left: 5px;
    margin-right: -12px;
  }

  .slick-slider .slick-arrow {
    &.slick-next,
    &.slick-prev {
      top: ${props => props.arrowverticalposition};
    }
  }
`;

const Arrow = styled('button')`
  border: 0;
  height: 50px;
  width: 50px;
  background: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.below.lg} {
    height: 40px;
    width: 40px;
  }

  /* &:hover {
    background: #e3dcce;
  } */

  &.slick-next {
    right: -40px;
    left: auto;
    z-index: 1;
    svg {
      transform: rotate(270deg);

      ${theme.below.lg} {
        margin-right: 2px;
        display: none;
      }
    }
    ${theme.below.lg} {
      right: 15px;
    }
  }
  &.slick-prev {
    left: -40px;
    right: auto;
    z-index: 1;
    svg {
      transform: rotate(90deg);

      ${theme.below.lg} {
        margin-right: 2px;
        display: none;
      }
    }
    ${theme.below.lg} {
      left: 15px;
    }
  }
  &:focus {
    outline: none;
  }

  svg {
    color: #111;
    width: 25px;
    height: 11px;
  }
`;

export const SliderContext = createContext();

const CustomArrow = props => {
  const { className, onClick } = props;
  return (
    <Arrow className={className} onClick={onClick}>
      <Carrot />
    </Arrow>
  );
};

export const SliderWithButtons = ({
  desktopSlides,
  mobileSlides,
  children
}) => {
  const [dragging, setDragging] = useState(false);

  const arrowVerticalPosition = '30%';

  const desktopSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    slidesToShow: desktopSlides,
    slidesToScroll: 1,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false)
    // adaptiveHeight: true
  };

  const mobileSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    slidesToShow: mobileSlides,
    slidesToScroll: 1,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false)
  };

  return (
    <SliderWrapper
      className={'shared-slider-wrapper'}
      arrowverticalposition={arrowVerticalPosition}
    >
      <SliderContext.Provider value={{ dragging: dragging }}>
        <Above breakpoint="lg">
          {matches =>
            matches ? (
              <Slider {...desktopSettings}>{children}</Slider>
            ) : (
              <Slider {...mobileSettings}>{children}</Slider>
            )
          }
        </Above>
      </SliderContext.Provider>
    </SliderWrapper>
  );
};
