import React from 'react';
import { styled } from 'linaria/react';
import UIMaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';

const StackWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  width: 100%;
  a {
    color: black;
  }

  li {
    list-style-type: disc;
    list-style-position: inside;
  }

  &.size-guide {
    letter-spacing: 0.03em;
    width: 100%;
    font-size: 14px;
    margin-top: 20px;

    .html-content {
      padding: 0 40px;
      ${theme.below.lg} {
        padding: 0 20px;
      }
    }

    table {
      margin: 10px 0;
      width: 100%;
    }

    td:first-of-type {
      text-align: left;
      ${theme.below.lg} {
        min-width: 100px;
      }
    }

    td {
      text-align: center;
      ${theme.below.lg} {
        min-width: 40px;
      }
    }

    td:hover {
      background: gray;
      color: white;
    }

    tr {
      :nth-of-type(odd) {
        background: #f7f7f7;
      }
    }

    tr:hover {
      background: lightgray;
    }

    .notes {
      margin-top: -20px;
      margin-bottom: 30px;
      font-style: italic;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.03em;
    }

    ${theme.below.lg} {
      font-size: 10px;
    }

    .size-guide-inner {
      margin-bottom: 20px;
      ${theme.below.lg} {
        overflow: scroll;
        padding-bottom: 10px;
      }
    }
  }
`;

const JournalWrapper = styled(UIMaxWidth)`
  max-width: 800px;
  width: 80%;
  letter-spacing: 0.02rem;
  line-height: 1.5;

  h1,
  h2,
  h3 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.03rem;
  }

  ${theme.below.lg} {
    width: 100%;
  }
  p {
    font-size: 16px;
    /* line-height: 23px; */
  }

  a {
    color: black;
  }
`;

export const Stack = ({ children }) => {
  return <StackWrapper>{children}</StackWrapper>;
};

export const SizeGuideStack = ({ children }) => {
  return <StackWrapper className="size-guide">{children}</StackWrapper>;
};

export const JournalStack = ({ children }) => {
  return (
    <JournalWrapper>
      <StackWrapper>{children}</StackWrapper>
    </JournalWrapper>
  );
};
