import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  max-width: 60rem;
  width: 80%;
  margin: auto;

  ${theme.below.lg} {
    flex-direction: column-reverse;
    width: 100%;
    padding: 0 1rem;
  }

  &.reverse {
    flex-direction: row-reverse;

    ${theme.below.lg} {
      flex-direction: column-reverse;
      width: 100%;
      padding: 0 1rem;
    }

    .html-content {
      padding-left: 50px;
      ${theme.below.lg} {
        padding: 0;
      }
    }
  }

  > * {
    flex: 1;
    align-self: center;
  }

  .html-content {
    padding-right: 50px;
    ${theme.below.lg} {
      padding-right: 0;
    }
  }

  a {
    color: black;
  }
`;

const ImageWrapper = styled('div')`
  ${theme.below.lg} {
    width: 100%;
    margin: 10px 0;
  }
`;

export const JournalRow = ({ children, reverse }) => {
  return (
    <Wrapper className={reverse?.value ? 'reverse' : ''}>{children}</Wrapper>
  );
};

export const JournalImage = ({ desktopImageUrl, desktopImageAspect }) => {
  return (
    <ImageWrapper>
      <Image
        aspect={desktopImageAspect.value}
        src={desktopImageUrl.value}
        cover
      />
    </ImageWrapper>
  );
};
