import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { styled } from 'linaria/react';
import React from 'react';
import { useQuery } from 'react-apollo';
import ContentPageContentQuery from '../ContentPage/ContentPageContentQuery.gql';
import { theme } from '../Theme';
import Spinner from '../ui/Spinner';
import CategoryContentQuery from './CategoryContentQuery.gql';
import { contentEditorComponents } from './ContentEditorComponents';

const SpinnerWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${theme.colors.gold};
  }
`;

export const DynamicContentRenderer = ({ categoryId, rendererComponents }) => {
  const { data, loading, error } = useQuery(CategoryContentQuery, {
    variables: {
      id: categoryId
    }
  });

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  if (!data || error) {
    return null;
  }
  const components = rendererComponents ?? contentEditorComponents;
  const items = data?.category?.data?.items;

  return (
    <>{items && <ContentRenderer items={items} components={components} />}</>
  );
};

export const DynamicPageContentRenderer = ({ pageId, rendererComponents }) => {
  const { data, loading, error } = useQuery(ContentPageContentQuery, {
    variables: {
      id: pageId
    }
  });

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  if (!data || error) {
    return null;
  }
  const components = rendererComponents ?? contentEditorComponents;
  const items = data?.page?.data?.items;

  return (
    <>{items && <ContentRenderer items={items} components={components} />}</>
  );
};
