import React from 'react';
import { JournalPostCard } from './JournalPostCard';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import t from '@jetshop/intl';
import { GoldBar } from '../Layout/GoldBar';
import { useSortedPosts } from './JournalPage';
import PagePreviewQuery from '../PagePreviewQuery.gql';
import { useQuery } from '@apollo/react-hooks';

const LatestJournalPostsWrapper = styled('div')`
  display: flex;
  ${theme.below.lg} {
    overflow: scroll;
    padding: 10px;
    div {
      min-width: 70%;
      padding: 0 10px;
    }
  }
`;
const Wrapper = styled('div')`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  &.menu {
    align-items: flex-start;
    margin: 40px 0;
  }
`;

const Header = styled('h3')`
  font-size: 16px;
  line-height: 23px;
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.darkgrey};
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const LatestJournalPosts = ({ posts, category, menu }) => {
  return (
    <Wrapper className={menu ? 'menu' : ''}>
      {!menu && (
        <GoldBar>
          <Header>
            {t('Latest ')}
            {t('Inspiration and tips')}
          </Header>
        </GoldBar>
      )}
      <LatestJournalPostsWrapper>
        {posts &&
          posts?.map(post => (
            <React.Fragment key={post.id}>
              <JournalPostCard
                postslength={posts?.length}
                post={post}
                menu={menu}
              />
            </React.Fragment>
          ))}
      </LatestJournalPostsWrapper>
    </Wrapper>
  );
};

export const StartPageLatestPosts = React.memo(() => {
  const { data } = useQuery(PagePreviewQuery, {
    variables: {
      id: 53
    },
    errorPolicy: 'ignore'
  });
  const page = data?.page ?? null;
  const allPosts = useSortedPosts(page);
  if (!allPosts) return null;

  return <LatestJournalPosts posts={allPosts.slice(0, 3)} />;
});

export const MenuLatestPost = () => {
  const { data } = useQuery(PagePreviewQuery, {
    variables: {
      id: 53
    },
    errorPolicy: 'ignore'
  });
  const page = data?.page ?? null;
  const allPosts = useSortedPosts(page);
  if (!allPosts) return null;

  return <LatestJournalPosts posts={allPosts.slice(0, 3)} menu={true} />;
};
