import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import Image from '@jetshop/ui/Image/Image';
import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import { GoldBar } from '../Layout/GoldBar';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { contentEditorComponents } from '../ContentEditor/ContentEditorComponents';

export const CategoryHeaderWrapper = styled(MaxWidth)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 28px;
  ${theme.below.xl} {
    max-width: 100%;
    width: 100%;
    padding: 0px;
    padding-top: 28px;
  }

  ${theme.below.lg} {
    padding-top: 0 !important;
  }
`;

const CategoryImageWrapper = styled('div')`
  height: 320px;
  width: 100%;
  ::after {
    display: block;
    position: relative;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.195) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    content: '';
    margin-top: -320px;
    height: 320px;
  }
  ${theme.below.lg} {
    height: 217px;
    ::after {
      margin-top: -217px;
      height: 217px;
    }
  }
`;

export const NoImageWrapper = styled('div')`
  margin-top: 30px;
`;

const CategoryName = styled('h2')`
  font-family: Silk Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  ${theme.below.lg} {
    font-size: 24px;
    line-height: 24px;
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 8px;

  a,
  li {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #333333 !important;
    text-decoration: none;
    ${theme.below.lg} {
      font-size: 11px;
    }

    &:after {
      padding: 0 8px;
    }
  }
`;

const headerImageStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  ${StyledBreadcrumbs} {
    a,
    li {
      color: white !important;
    }
  }
`;

export const Inner = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Content = ({ category, breadcrumbProps, content }) => (
  <Inner>
    <StyledBreadcrumbs {...breadcrumbProps} />
    <GoldBar>
      <CategoryName data-testid="page-header">
        {category?.mainHeader ? category?.mainHeader : category?.name}
      </CategoryName>
    </GoldBar>
  </Inner>
);

const CategoryHeader = ({
  category,
  parents,
  imageSrc,
  breadcrumbs = { value: true },
  content,
  showTopItems
}) => {
  const { value } = breadcrumbs;
  const breadcrumbProps = {
    breadcrumbText: value ? category.breadcrumbText : '',
    parents
  };
  const isImageBackground =
    imageSrc || (category.images && category.images.length > 0);

  const getItemsWithoutPropNamed = (key, items) => {
    return items?.filter(item => {
      return !item?.properties?.find(
        prop => prop?.name === key && prop?.value?.value
      );
    });
  };
  const topItems = getItemsWithoutPropNamed('seo', category?.data?.items);

  return (
    <CategoryHeaderWrapper>
      {topItems && showTopItems && (
        <ContentRenderer
          items={topItems}
          components={contentEditorComponents}
        />
      )}
      {isImageBackground ? (
        <CategoryImageWrapper>
          <Image
            src={imageSrc?.value || category.images}
            fillAvailableSpace
            cover
            quality={90}
            className={headerImageStyles}
            critical={true}
          >
            <Content
              category={category}
              breadcrumbProps={breadcrumbProps}
              content={content}
            />
          </Image>
        </CategoryImageWrapper>
      ) : (
        <NoImageWrapper>
          <Content
            category={category}
            breadcrumbProps={breadcrumbProps}
            content={content}
          />
        </NoImageWrapper>
      )}
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeader;
