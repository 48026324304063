import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import MaxWidth from '../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';
import { setVariableFromProp } from '../../utils/HelpFunctions';
import { Text } from './Text';
import { Above } from '@jetshop/ui/Breakpoints';
import { useInView } from 'react-intersection-observer';

const LargeIconUspRowContainer = styled(MaxWidth)`
  padding: 0 10%;
  height: auto;

  ${theme.below.lg} {
    padding: 0 20px;
  }
`;
const LargeIconUspRowWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-column-gap: 50px;
  grid-row-gap: 80px;

  ${theme.below.lg} {
    grid-template-columns: repeat(1, 100%);
  }
`;

export const LargeIconUspRow = ({ children }) => {
  return (
    <LargeIconUspRowContainer>
      <LargeIconUspRowWrapper>{children}</LargeIconUspRowWrapper>
    </LargeIconUspRowContainer>
  );
};

const LargeIconUspItemWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  height: auto;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled('div')`
  width: 80px;
  height: auto;
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0 30px 0 50px;

  .text-component {
    h3 {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    p {
      text-align: left;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 0.05em;
      text-transform: none;
    }
  }
`;

export const LargeIconUspItem = ({ imageUrl, title, text, hideInMobile }) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);
  const mobileHide = setVariableFromProp(hideInMobile);
  const imgUrl = setVariableFromProp(imageUrl);

  const comp = () => {
    return (
      <LargeIconUspItemWrapper ref={ref}>
        <IconWrapper>
          <Image src={imgUrl} aspect={'1:1'} sizes={'100'} cover />
        </IconWrapper>
        <TextWrapper>
          <Text size={'SMALLTITLE'} text={title} color={'#000000'} />
          <Text size={'TEXT'} text={text} color={'#000000'} />
        </TextWrapper>
      </LargeIconUspItemWrapper>
    );
  };

  return (
    <Above breakpoint="lg">
      {matches => (matches ? <>{comp()}</> : <>{mobileHide ? null : comp()}</>)}
    </Above>
  );
};
