import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import { RowTitle } from './RowTitle';
import { Text } from './Text';

const LinkRowWrapper = styled(MaxWidth)`
  align-items: center;
`;

const LinksContainer = styled('div')`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;

  ${theme.below.lg} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const LinkRow = ({ title, children }) => {
  return (
    <LinkRowWrapper>
      <RowTitle title={title} />
      <LinksContainer parts={children?.length}>{children}</LinksContainer>
    </LinkRowWrapper>
  );
};

const LinkItem = styled(Link)`
  position: relative;
  text-decoration: none;
  outline: 1px solid #ebebeb;
  padding: 0 10px;
  width: 33.333%;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-component {
    z-index: 1;
    p {
      font-weight: 700;
      height: 40px;
      line-height: 40px;
    }
  }

  :hover,
  :active {
    outline: 1px solid ${theme.colors.gold};
    .text-component {
      p {
        color: ${theme.colors.gold};
      }
    }
  }
`;

export const LinkRowItem = ({ linkName, link }) => {
  return (
    <LinkItem to={link?.value}>
      <Text size={'TEXT'} color={'#333333'} text={linkName}></Text>
    </LinkItem>
  );
};
