import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import { ReactComponent as Logo } from './LOGOWTAG.svg';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import { IconRow } from './IconRow';

const DesktopHeaderWrapper = styled('div')`
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const HeaderColumn = styled('div')`
  display: flex;
  align-items: center;
  /* flex: 1; */

  &.center {
    flex: none;
  }
  &.right {
    justify-content: flex-end;
  }
  &.left {
    button {
      background: none;
    }
  }
`;

const LogoLink = styled(Link)``;

const DesktopHeaderMenuWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: flex-start;
  margin-left: -0.5em;
`;

const DesktopTopWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const DesktopHeader = ({
  data,
  searchOpen,
  setSearchOpen,
  loggedIn
}) => {
  return (
    <DesktopHeaderWrapper id="site-header">
      <DesktopTopWrapper>
        <HeaderColumn className="left logo-wrapper">
          <LogoLink to="/" className="logo-link">
            <Logo />
          </LogoLink>
        </HeaderColumn>
        <HeaderColumn className="right">
          <IconRow
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
            loggedIn={loggedIn}
          />
        </HeaderColumn>
      </DesktopTopWrapper>
      <DesktopHeaderMenuWrapper>
        <CategoryMenu data={data} />
      </DesktopHeaderMenuWrapper>
    </DesktopHeaderWrapper>
  );
};
