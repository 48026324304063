import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { useQuery } from 'react-apollo';
import { theme } from '../../../Theme';
import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';
import { JournalComponents } from '../../../ContentEditor/ContentEditorComponents';
import { DynamicContentRenderer } from '../../../ContentEditor/DynamicContentRenderer';
import TopBrandsQuery from './TopBrandsQuery.gql';

const MaxWidth = styled(UIMaxWidth)`
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  justify-content: space-between;
  max-width: 90rem;
  padding: 26px 20px;
  min-height: 550px;

  &.outlet {
    min-height: 250px;
  }
`;
const ContentFit = styled('div')``;

const InnerWrapper = styled('div')`
  width: 100%;
  max-height: 85vh;
  overflow-y: scroll;
  background: ${theme.colors.tablegrey};
`;

const Wrapper = styled('section')`
  top: 28px;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: -16px;
  background: ${theme.colors.white};
  overflow: hidden;
  z-index: 1;
  will-change: height;
  transition: height 0.2s ease-in-out;
`;

const Background = styled('div')`
  &.display:hover {
    display: none;
  }
  &.display {
    margin-top: 120px;
    background: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -3;
  }
`;

const MenuLinkWrapper = styled('div')`
  width: 100%;
  column-count: 5;
  display: inline-block;
  column-rule: solid 1px ${theme.colors.lightmediumgrey};
  border-left: solid 1px ${theme.colors.lightmediumgrey};
  column-gap: 0;
  > div {
    margin-left: 10px;
  }

  &.brands {
    column-count: 2;
    column-rule: none;
    border-left: none;
    margin-left: -10px;
    .brands-journal-wrapper {
    }
    .brands-wrapper {
      display: flex;
      justify-content: flex-start;
      column-gap: 60px;
      > div {
        border-left: solid 1px ${theme.colors.lightmediumgrey};
        > div {
          margin-left: 10px;
        }
      }
    }
    .showall-brands {
      margin-top: 40px;
    }
  }

  &.few {
    column-count: ${props => props.fewcolumns};
  }

  &.inspo {
    border-left: none;
    display: flex;

    .journal-menu-wrapper {
      flex-wrap: wrap;
    }
    .postwrapper {
      max-width: 30%;
      width: 100%;
    }
  }

  &.house {
    border-left: none;
    display: flex;
    align-items: flex-start;
    margin-left: -10px;

    .house-wrapper {
      > div {
        margin-left: 10px;
      }
      display: flex;
      width: 100%;
      max-width: 50%;
      flex-direction: column;
      border-left: solid 1px ${theme.colors.lightmediumgrey};
    }
    .house-journal-wrapper {
      width: 100%;
      max-width: 50%;
    }
  }

  &.outlet {
    column-rule: none;
    border-left: none;
    margin-left: -10px;

    .outlet-wrapper {
      border-left: solid 1px ${theme.colors.lightmediumgrey};
      > div {
        margin-left: 10px;
      }
    }
  }
`;

const InnerMenuWrapper = styled('div')`
  width: 100%;
  /* max-width: 78%; */
  max-width: 100%;

  .gold-bar {
    width: fit-content;
    &:after {
      bottom: -5px;
    }
  }
`;

const ShowAllBrandsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 40px;
  margin-left: -20px !important;
`;

const HouseMenuContent = ({ activeCategory, closeNav }) => {
  return (
    <>
      {activeCategory?.subcategories.map((cat, index) => (
        <SubMenuSection
          heading={cat}
          categories={cat.subcategories}
          onSelect={closeNav}
          key={cat.id}
        />
      ))}
    </>
  );
};

const BrandsMenuContent = ({
  activeCategory,
  closeNav,
  brandsData,
  topBrandsData
}) => {
  return (
    <>
      {activeCategory &&
        topBrandsData?.data?.category?.subcategories?.map(cat => {
          return (
            <TopBrands
              category={cat}
              brandsData={brandsData.subcategories}
              closeNav={closeNav}
            />
          );
        })}
    </>
  );
};

const OutletMenuContent = ({ activeCategory, closeNav }) => {
  return (
    <>
      {activeCategory?.subcategories.map((cat, index) => (
        <SubMenuSection
          heading={cat}
          categories={cat.subcategories}
          onSelect={closeNav}
          key={cat.id}
        />
      ))}
    </>
  );
};

const JournalMenuContent = ({ activeCategory, closeNav }) => {
  return (
    <>
      {activeCategory && (
        <div onClick={closeNav}>
          <DynamicContentRenderer
            categoryId={869}
            rendererComponents={JournalComponents}
          />
        </div>
      )}
    </>
  );
};

const TopBrands = ({ brandsData, category, closeNav }) => {
  let subcategories = [];

  category?.subcategories.forEach(subCat => {
    const matchedBrand = brandsData.find(brand => brand?.name === subCat?.name);
    if (matchedBrand) {
      subcategories.push(matchedBrand);
    }
  });

  return (
    <div>
      <SubMenuSection
        heading={category}
        categories={subcategories}
        onSelect={closeNav}
        key={category.id}
      />
    </div>
  );
};

const SubMenuContent = ({
  activeCategory,
  closeNav,
  brandsData,
  topBrandsData
}) => {
  //HOUSE
  if (activeCategory?.id === 826) {
    return (
      <>
        <div className="house-wrapper">
          <HouseMenuContent
            activeCategory={activeCategory}
            closeNav={closeNav}
          />
        </div>
        <div onClick={closeNav} className="house-journal-wrapper">
          <DynamicContentRenderer
            categoryId={868}
            rendererComponents={JournalComponents}
          />
        </div>
      </>
    );
  }

  //BRANDS
  if (activeCategory?.id === 329) {
    return (
      <>
        <div className="brands-wrapper">
          <BrandsMenuContent
            activeCategory={activeCategory}
            closeNav={closeNav}
            brandsData={brandsData}
            topBrandsData={topBrandsData}
          />
        </div>
        {activeCategory && activeCategory?.id === 329 && (
          <div className="showall-brands">
            <Link to="/vare-merker" onClick={closeNav}>
              Se alle våre merker
            </Link>
          </div>
        )}
        <div onClick={closeNav} className="brands-journal-wrapper">
          <DynamicContentRenderer
            categoryId={870}
            rendererComponents={JournalComponents}
          />
        </div>
      </>
    );
  }

  //OUTLET
  if (activeCategory?.id === 862) {
    return (
      <>
        <div className="outlet-wrapper">
          <OutletMenuContent
            activeCategory={activeCategory}
            closeNav={closeNav}
          />
        </div>
        <div onClick={closeNav} className="outlet-journal-wrapper">
          <DynamicContentRenderer
            categoryId={871}
            rendererComponents={JournalComponents}
          />
        </div>
      </>
    );
  }

  //JOURNAL
  if (activeCategory?.id === 867) {
    return (
      <JournalMenuContent activeCategory={activeCategory} closeNav={closeNav} />
    );
  }

  return (
    <>
      {activeCategory?.subcategories.map((cat, index) => (
        <SubMenuSection
          heading={cat}
          categories={cat.subcategories}
          onSelect={closeNav}
          key={cat.id}
        />
      ))}
    </>
  );
};

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  const brandsData = data?.categories?.find(category => category?.id === 329);
  const topBrandsData = useQuery(TopBrandsQuery, {
    variables: {
      id: 839
    }
  });

  return (
    <>
      <Background className={pose === 'open' ? 'display' : ''} />
      <Wrapper>
        <InnerWrapper>
          <ContentFit>
            <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
              <MaxWidth className={activeCategory?.id === 862 && 'outlet'}>
                <>
                  <InnerMenuWrapper>
                    <MenuLinkWrapper
                      className={cx(
                        activeCategory?.id === 329 && 'brands',
                        activeCategory?.id === 867 && 'inspo',
                        activeCategory?.id === 826 && 'house',
                        activeCategory?.id === 862 && 'outlet',
                        activeCategory?.subcategories?.length < 3 && 'few'
                      )}
                      brandscolumns={
                        topBrandsData?.data?.category?.subcategories?.length
                      }
                      fewcolumns={activeCategory?.subcategories?.length}
                    >
                      <SubMenuContent
                        activeCategory={activeCategory}
                        closeNav={closeNav}
                        brandsData={brandsData}
                        topBrandsData={topBrandsData}
                      />
                    </MenuLinkWrapper>

                    {activeCategory && activeCategory?.id === 867 && (
                      <ShowAllBrandsWrapper>
                        <div>
                          <Link to="/inspirasjon-og-raad" onClick={closeNav}>
                            Se mer inspirasjon og alle våre råd her
                          </Link>
                        </div>
                      </ShowAllBrandsWrapper>
                    )}
                  </InnerMenuWrapper>
                </>
              </MaxWidth>
            </AnimateHeight>
          </ContentFit>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

export default SubMenuWrapper;
