import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { setVariableFromProp, validateNumber } from '../../utils/HelpFunctions';
import { filterChildrenByKey } from '../../utils/HelpFunctions';

export const HeroMaxWidth = styled('div')`
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 85rem;
  margin: auto;

  &.fullWidth {
    max-width: 100%;
  }
  &.padding {
    padding: 0 40px;
    ${theme.below.lg} {
      padding: 0 20px;
    }
  }

  ${theme.below.lg} {
    padding: 0;
    max-width: 100%;

    .titles-wrapper {
      max-width: 80%;
    }

    br {
      display: none;
    }
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 40px;
  width: 100%;
  position: absolute;

  &.TOP {
    top: 10%;
  }

  &.MIDDLE {
    top: 50%;
  }

  &.BOTTOM {
    bottom: 10%;
  }

  &.LEFT {
    align-items: flex-start;
  }

  &.CENTER {
    align-items: center;
  }

  &.RIGHT {
    align-items: flex-end;
  }

  ${theme.below.lg} {
    min-width: 100%;
    padding: 0;
  }
`;

const ButtonRow = styled('div')`
  display: flex;
  width: 100%;
  min-width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  .button-component {
    margin: 5px;
  }

  &.LEFT {
    justify-content: flex-start;
  }

  &.CENTER {
    justify-content: center;
  }

  &.RIGHT {
    justify-content: flex-end;
  }
`;

const VideoFrameWrapper = styled('div')`
  position: relative;
  padding-bottom: ${props => props.bottompadding}%;
  margin-bottom: 0px;
  width: 100% !important;
  height: auto !important;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const Video = ({ videoUrl, ratio = '16:9', autoplay = true }) => {
  const vUrl = setVariableFromProp(videoUrl);
  const auto = setVariableFromProp(autoplay);
  const videoRatio = setVariableFromProp(ratio);
  const videoID = vUrl?.split('https://vimeo.com/')?.[1];

  console.log('videoID', videoID);
  console.log('videoUrl', videoUrl);

  if (!videoID) return null;

  const convertBoolToInt = b => {
    return b ? 1 : 0;
  };

  const intFromAutoPlay = convertBoolToInt(auto);
  const playerVideoUrl = `https://player.vimeo.com/video/${videoID}?autoplay=${intFromAutoPlay}&loop=${intFromAutoPlay}&title=0&byline=0&portrait=0&controls=${!auto}&muted=${auto}`;

  const videoRatioToPercent = ratio => {
    const widthAndHeight = ratio?.split(':');
    if (widthAndHeight?.[0] && widthAndHeight?.[1]) {
      const width = validateNumber(widthAndHeight?.[0]);
      const heigth = validateNumber(widthAndHeight?.[1]);
      return (heigth / width) * 100;
    }
    return null;
  };

  const videoProps = {
    height: 'auto',
    width: '100%'
  };

  return (
    <VideoFrameWrapper bottompadding={videoRatioToPercent(videoRatio)}>
      <iframe title={`Video ${videoID}`} {...videoProps} src={playerVideoUrl} />
    </VideoFrameWrapper>
  );
};

export const VideoHero = ({
  fullWidth,
  padding,
  desktopVideo,
  desktopVideoRatio,
  mobileVideo,
  mobileVideoRatio,
  children,
  horizontalPosition,
  verticalPosition
}) => {
  const vertAlign = setVariableFromProp(horizontalPosition);
  const horAlign = setVariableFromProp(verticalPosition);
  const filteredChildren = filterChildrenByKey(children, 'button', false);
  const buttonChildren = filterChildrenByKey(children, 'button', true);

  return (
    <>
      <Above breakpoint="lg">
        {matches => (
          <HeroMaxWidth
            className={cx(
              fullWidth?.value && 'fullWidth',
              padding?.value && 'padding'
            )}
          >
            {desktopVideo && mobileVideo && (
              <Video
                videoUrl={matches ? desktopVideo?.value : mobileVideo?.value}
                ratio={
                  matches ? desktopVideoRatio?.value : mobileVideoRatio?.value
                }
              />
            )}
            <ContentWrapper className={cx(vertAlign, horAlign)}>
              {filteredChildren}
              <ButtonRow className={cx(vertAlign, horAlign)}>
                {buttonChildren}
              </ButtonRow>
            </ContentWrapper>
          </HeroMaxWidth>
        )}
      </Above>
    </>
  );
};
