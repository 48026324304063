import React, { useEffect, useState, useRef } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const ScrollIndicatorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-top: 1px solid ${theme.colors.gold};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: sticky;
  top: ${props => props.topposition}px;
  background: white;
  z-index: 10;
  height: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in, height 1s ease-in;

  &.show {
    opacity: 1;
    height: 57px;
    transition: 0.4s ease-in-out;
  }
  h3 {
    font-size: 14px;
  }
`;

const JournalTextWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const JournalTypeName = styled('h3')`
  font-weight: 300;
  margin-right: 3px;
  font-style: italic;
`;
const JournalTitleName = styled('h3')`
  font-weight: 500;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: -2px;
`;

export const ScrollIndicator = ({ type, title }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [topPosition, setTopPosition] = useState(0);
  const [showScoll, setShowScroll] = useState(false);
  const scrollRef = useRef();

  const handleScroll = () => {
    if (window) {
      const parentObj = scrollRef?.current?.parentElement?.getBoundingClientRect();
      const viewportHeight = window.visualViewport.height;
      if (parentObj.bottom < viewportHeight) {
        setScrollPosition(100);
      } else {
        if (parentObj.top < 0) {
          setShowScroll(true);
        } else {
          setShowScroll(false);
        }
        const percent =
          100 * ((-1 * parentObj.top) / (parentObj.height - viewportHeight));
        setScrollPosition(percent ?? 0);
      }
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (typeof window === 'object') {
      const header = document?.getElementById('stickyHeader');
      //TODO - When CampaignBar is included in Header reduce height of Campainbar
      setTopPosition(header?.offsetHeight);
    }
  }, []);

  return (
    <ScrollIndicatorWrapper
      ref={scrollRef}
      topposition={topPosition}
      className={showScoll ? 'show' : null}
    >
      <JournalTextWrapper>
        {type && <JournalTypeName>{type}</JournalTypeName>}
        {title && <JournalTitleName> - {title}</JournalTitleName>}
      </JournalTextWrapper>
      <StatusBar percentage={scrollPosition} />
    </ScrollIndicatorWrapper>
  );
};

const GraphWrapper = styled('div')`
  width: calc(100% / 3);
  margin: 6px 0;
`;

const GraphBar = styled('div')`
  height: 2px;
  background: #d3d3d3;
  position: relative;
  width: 100%;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background: #333333;
    left: 0;
    height: 100%;
    width: ${props => Math.round(props.fill)}%;
  }
`;

const StatusBar = ({ percentage }) => {
  return (
    <GraphWrapper>
      <GraphBar fill={percentage} />
    </GraphWrapper>
  );
};
