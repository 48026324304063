import { styled } from 'linaria/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { setVariableFromProp } from '../../utils/HelpFunctions';
import MaxWidth from '../Layout/MaxWidth';

const PaymentAndDeliveryRowWrapper = styled('div')`
  width: 100%;
  padding: 40px 0;
  background: ${props => props.bgcolor};
`;

const FlexRow = styled(MaxWidth)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const PaymentAndDeliveryRow = ({ backgroundColor, children }) => {
  return (
    <PaymentAndDeliveryRowWrapper bgcolor={backgroundColor?.value}>
      <FlexRow>{children}</FlexRow>
    </PaymentAndDeliveryRowWrapper>
  );
};

const PaymentAndDeliveryRowItemWrapper = styled('div')`
  margin: 5px 10px;
  img {
    max-height: 50px;
    max-width: 150px;
  }
`;

export const PaymentAndDeliveryRowItem = ({ imageUrl }) => {
  return (
    <PaymentAndDeliveryRowItemWrapper>
      <img src={imageUrl.value} alt="Ferner Jacobsen" loading="lazy" />
    </PaymentAndDeliveryRowItemWrapper>
  );
};

const TryggEHandelWrapper = styled(PaymentAndDeliveryRowItemWrapper)``;

export const TryggEHandel = ({ logo }) => {
  const logoSrc = setVariableFromProp(logo);

  return (
    <TryggEHandelWrapper>
      <img id={'TEH_LOGOTYPE'} src={logoSrc} alt="TEH" loading="lazy" />
      <Helmet>
        <script src="/tryggehandel.js"></script>
      </Helmet>
    </TryggEHandelWrapper>
  );
};
