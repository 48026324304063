import React from 'react';
import { styled } from 'linaria/react';
import { GoldBar } from '../Layout/GoldBar';
import { setVariableFromProp } from '../../utils/HelpFunctions';

const RowTitleWrapper = styled('h2')`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #333333;
`;

export const RowTitle = ({ title }) => {
  const rTitle = setVariableFromProp(title);

  return (
    <GoldBar>
      <RowTitleWrapper>{rTitle}</RowTitleWrapper>
    </GoldBar>
  );
};
