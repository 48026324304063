import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';

const Wrapper = styled('div')`
  display: flex;
  max-width: 80rem;
  margin: auto;
  grid-gap: ${props => props.gapsize}rem;
  align-items: baseline;

  ${theme.below.lg} {
    padding: 0 16px;
    grid-gap: ${props => props.gapsize * 0.8}rem;
  }

  > div {
    flex: 1;
  }
`;

const ImageWrapper = styled('div')``;

export const JournalImages = ({ children, gapSize }) => {
  //Switchcase for desktop gap
  let matchedGapsize = 0;
  switch (gapSize?.value) {
    case 'XSMALL':
      matchedGapsize = 0.5;
      break;
    case 'SMALL':
      matchedGapsize = 1;
      break;
    case 'MEDIUM':
      matchedGapsize = 3;
      break;
    case 'LARGE':
      matchedGapsize = 6;
      break;
    case 'XLARGE':
      matchedGapsize = 8;
      break;
    default:
      matchedGapsize = 0;
      break;
  }

  return <Wrapper gapsize={matchedGapsize}>{children}</Wrapper>;
};

//If url is missing return null.
const RenderImage = ({ image, ratio }) => {
  if (!image) {
    return null;
  }

  return <Image aspect={ratio} src={image} cover />;
};

export const JournalImagesItem = ({
  desktopImageUrl,
  desktopImageAspect,
  mobileImageUrl,
  mobileImageAspect
}) => {
  return (
    <Above breakpoint="lg">
      {matches =>
        matches ? (
          desktopImageUrl?.value ? (
            <ImageWrapper>
              <RenderImage
                image={desktopImageUrl?.value}
                ratio={desktopImageAspect?.value}
              />
            </ImageWrapper>
          ) : null
        ) : mobileImageUrl?.value ? (
          <ImageWrapper>
            <RenderImage
              image={mobileImageUrl?.value}
              ratio={mobileImageAspect?.value}
            />
          </ImageWrapper>
        ) : null
      }
    </Above>
  );
};
