import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import React, { createContext, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { ReactComponent as Carrot } from '../../svg/Caret.svg';

const SliderWrapper = styled('div')`
  .slick-dots {
    display: flex !important;
    li {
      width: 100%;
      background: #d4d4d4;
      height: 4px;
      &.slick-active {
        background: #b99864;
      }
    }
    button {
      display: none;
    }
  }
`;

const Arrow = styled('button')`
  &.slick-next {
    display: none;
  }
  &.slick-prev {
    display: none;
  }
  &:focus {
    outline: none;
  }
`;

export const SliderContext = createContext();

const CustomArrow = props => {
  const { className, onClick } = props;
  return (
    <Arrow className={className} onClick={onClick}>
      <Carrot />
    </Arrow>
  );
};

export const SharedSlider = ({ desktopSlides, mobileSlides, children }) => {
  const [dragging, setDragging] = useState(false);

  const desktopSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    slidesToShow: desktopSlides ?? 3,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false)
  };

  const mobileSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    slidesToShow: mobileSlides ?? 1,
    slidesToScroll: 1,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false)
  };

  return (
    <SliderWrapper>
      <SliderContext.Provider value={{ dragging: dragging }}>
        <Above breakpoint="lg">
          {matches =>
            matches ? (
              <Slider {...desktopSettings}>{children}</Slider>
            ) : (
              <Slider {...mobileSettings}>{children}</Slider>
            )
          }
        </Above>
      </SliderContext.Provider>
    </SliderWrapper>
  );
};
