import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';
import { RowTitle } from './RowTitle';

const ColoredTextRowWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.bgcolor};
  ${theme.below.lg} {
    padding: 40px 20px 40px 20px;
  }
`;

const HtmlWrapper = styled('div')`
  margin-top: 25px;
  width: 50%;

  ${theme.below.lg} {
    width: 100%;
  }
  p {
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.03em;
  }
`;

export const ColoredTextRow = ({ backgroundColor, title, html }) => {
  return (
    <ColoredTextRowWrapper bgcolor={backgroundColor?.value}>
      {title?.value && <RowTitle title={title} />}
      {html?.value && (
        <HtmlWrapper
          dangerouslySetInnerHTML={{
            __html: html.value
          }}
        />
      )}
    </ColoredTextRowWrapper>
  );
};
