import { styled } from 'linaria/react';
import React, { useState } from 'react';
import useFindify from '@findify/react-bundle';
import { theme } from '../Theme';
import { useInView } from 'react-intersection-observer';

const FindifyRecommendationWrapper = styled('div')`
  padding: 0;
  .findify-components--text__title {
    margin-bottom: 40px;
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    &:after {
      height: 2px;
      width: 35px;
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
      background: ${theme.colors.gold};
      content: '';
    }
  }
  &.min-height {
    min-height: 390px;
  }
  &.no-min-height {
    min-height: 0px;
  }
  ${theme.above.lg} {
    align-self: center;
    max-width: 1280px;
  }
`;

// slot: id?.value ? id?.value : id

const FindifyRecommendations = ({ id, product }) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  let recObj = {
    type: 'recommendation',
    config: {
      slot: id
    }
  };

  if (product) {
    recObj.options = {
      item_ids: [product?.articleNumber],
      rules: [
        {
          action: 'exclude',
          type: 'text',
          name: 'id',
          values: [
            {
              value: [product?.articleNumber]
            }
          ]
        }
      ]
    };
  }

  const [container, isReady, hasError] = useFindify(recObj);

  return (
    <FindifyRecommendationWrapper
      ref={ref}
      className={product ? 'no-min-height' : 'min-height'}
    >
      {/* {isReady && <div ref={container} />} */}
      {showed && (
        <>
          <div ref={container} />
          {!isReady && !hasError && '...'}
        </>
      )}
    </FindifyRecommendationWrapper>
  );
};

export default FindifyRecommendations;
