import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { RowTitle } from './RowTitle';
import { useInView } from 'react-intersection-observer';

const PreviewArticleRowWrapper = styled('div')`
  display: flex;
  margin: 20px 0;

  ${theme.below.lg} {
    flex-direction: column;
  }
`;

export const PreviewArticleRow = ({ title, children }) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  return (
    <div ref={ref}>
      <RowTitle title={title} />
      <PreviewArticleRowWrapper>{children}</PreviewArticleRowWrapper>
    </div>
  );
};
