import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { setVariableFromProp } from '../../utils/HelpFunctions';
import { theme } from '../Theme';

const ButtonWrapper = styled('div')`
  min-width: 150px;
  height: 38px;
  a,
  p {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 38px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    transition: 0.6s;
  }

  a::before,
  p::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0%;
    z-index: 1;
    transition: 0.2s;
    @media (prefers-reduced-motion) {
      transition: 0s;
    }
  }

  &.GOLD {
    a,
    p {
      background: ${theme.colors.gold};
      color: ${theme.colors.white};
    }

    a::before,
    p::before {
      background: ${theme.colors.white};
      color: rgba(0, 0, 0, 0);
    }

    &:hover,
    active {
      a::before,
      p::before {
        color: ${theme.colors.black};
        height: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        content: attr(title);
      }
    }
  }

  &.WHITE {
    a,
    p {
      background: ${theme.colors.white};
      color: ${theme.colors.black};
    }

    a::before,
    p::before {
      background: ${theme.colors.gold};
      color: rgba(0, 0, 0, 0);
    }

    &:hover,
    &:active {
      a::before,
      p::before {
        color: ${theme.colors.white};
        height: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        content: attr(title);
      }
    }
  }
`;

export const Button = ({ theme, text, link }) => {
  const btnTheme = setVariableFromProp(theme);
  const btnText = setVariableFromProp(text);
  const btnLink = setVariableFromProp(link);

  const elementEvaluator = () => {
    if (btnLink) {
      return (
        <Link title={btnText} to={btnLink}>
          {btnText}
        </Link>
      );
    } else {
      return <p title={btnText}>{btnText}</p>;
    }
  };

  return (
    <ButtonWrapper className={cx(btnTheme, 'button-component')}>
      {elementEvaluator()}
    </ButtonWrapper>
  );
};
