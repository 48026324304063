import React, { useLayoutEffect } from 'react';
import useFindify from '@findify/react-bundle';
import { useHistory } from 'react-router';
import { styled } from 'linaria/react';

const StyledSearchField = styled('div')`
  & {
    font-size: 16px !important;
    display: flex;
    height: 2rem;
    width: 100% !important;
    justify-content: center;
    input {
      border: 0;
      background: #f3f3f3;
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      &:focus {
        outline: none;
      }
    }
  }
`;
const PosedSearchBarContainer = styled('div')`
  display: flex;
  align-items: center;
`;
const InnerSearchBar = styled('div')``;

const SearchBar = ({ searchOpen, setSearchOpen }) => {
  const history = useHistory();
  const [container] = useFindify({ type: 'autocomplete', history });

  //Sets autofocus when search is triggered.
  useLayoutEffect(() => {
    if (searchOpen && container) {
      document.getElementById('fd-search').focus();
      setTimeout(() => {
        document.getElementById('fd-search').click();
      }, 50);
    }
  }, [searchOpen, container]);

  return (
    <>
      {searchOpen && (
        <PosedSearchBarContainer key={'searchBarPosed'}>
          <InnerSearchBar>
            <StyledSearchField>
              <input ref={container} id="fd-search" type="search" />
            </StyledSearchField>
          </InnerSearchBar>
        </PosedSearchBarContainer>
      )}
    </>
  );
};

export { SearchBar };
