import t from '@jetshop/intl';
import React from 'react';
import { css, cx } from 'linaria';
import MaxWidth from '../Layout/MaxWidth';

export function Favourites() {
  return (
    <MaxWidth className={cx(container)}>
      {/* TODO - open drawer */}
      <h2>{t('Favourites')}</h2>
      <p>
        {t('See favourite list by clicking the heart in the top of the page')}
      </p>
    </MaxWidth>
  );
}

const container = css`
  margin-top: 2em;
  min-height: 300px;
`;
