import React from 'react';
import { GoldDot } from '../Layout/GoldDot';
import { HtmlContent } from './HtmlContent';

export const GoldDotHtmlContent = ({ foregroundColor, htmlcontent }) => {
  return (
    <GoldDot>
      <HtmlContent
        foregroundColor={foregroundColor}
        htmlcontent={htmlcontent}
      />
    </GoldDot>
  );
};
