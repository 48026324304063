import React from 'react';
import { styled } from 'linaria/react';

const ContentWrapper = styled('div')`
  color: ${props => props.color};
`;

export const HtmlContent = ({ foregroundColor, htmlcontent }) => {
  return (
    <ContentWrapper
      className={'html-content'}
      color={foregroundColor?.value ?? 'black'}
      dangerouslySetInnerHTML={{
        __html: htmlcontent?.value
      }}
    ></ContentWrapper>
  );
};
