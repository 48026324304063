import React from 'react';
import { styled } from 'linaria/react';

const JournalMenuWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .postwrapper {
    padding: 0;
  }
`;

export const JournalMenu = ({ children }) => {
  return (
    <JournalMenuWrapper className="journal-menu-wrapper">
      {children}
    </JournalMenuWrapper>
  );
};
