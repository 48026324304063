import React from 'react';
import { styled } from 'linaria/react';
import { Text } from './Text';
import { theme } from '../Theme';
import {
  filterChildrenByKey,
  setVariableFromProp
} from '../../utils/HelpFunctions';
import logo from '../../svg/Ferner_logo.png';
import NewsletterField from '../Newsletter/NewsletterField';
import Image from '@jetshop/ui/Image';

const FooterRowWrapper = styled('div')`
  width: 100%;
  background: ${props => props.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  ${theme.below.lg} {
    padding: 30px 22px;
  }

  img {
    margin-bottom: 50px;
    width: fit-content;
    height: fit-content;
  }
`;

const ContentWrapper = styled('div')`
  max-width: 62rem;
  position: relative;
  width: 100%;
  display: flex;
  padding-bottom: 40px;

  ${theme.below.lg} {
    flex-direction: column;
  }

  a {
    text-decoration: none;
    text-align: left;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400;
    color: #656565 !important;
    letter-spacing: 0;
    ${theme.below.lg} {
      font-size: 12px;
    }
  }

  .text-component {
    h3 {
      text-align: left;
      font-size: 12px;
      font-weight: 700;
      color: #3d3d3d !important;
      letter-spacing: 0;
      margin-bottom: 5px;
      ${theme.below.lg} {
        font-size: 12px;
      }
    }

    p {
      text-align: left;
      text-transform: none;
      font-size: 12px;
      font-weight: 400;
      color: #656565 !important;
      letter-spacing: 0;
      ${theme.below.lg} {
        font-size: 12px;
      }
    }
  }
`;

const LinksWrapper = styled('div')`
  flex: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 200px;
`;

const RightWrapper = styled('div')`
  flex: 1;
  display: flex;
  ${theme.below.lg} {
    flex-direction: column;
  }

  &:empty {
    flex: 0;
  }
`;

export const FooterRow = ({ backgroundColor, children }) => {
  const footerItems = filterChildrenByKey(children, 'footerRowItem', true);
  const contactItems = filterChildrenByKey(children, 'footerRowContacts', true);
  const newsLetterItem = filterChildrenByKey(
    children,
    'footerRowNewsLetter',
    true
  );
  const socialMediaItems = filterChildrenByKey(
    children,
    'socialMediaLinks',
    true
  );

  return (
    <FooterRowWrapper background={backgroundColor?.value}>
      <img src={logo} alt="Ferner Jacobsen" />
      <ContentWrapper>
        <LinksWrapper>
          {footerItems}
          {socialMediaItems}
        </LinksWrapper>
        <RightWrapper>
          {contactItems}
          {newsLetterItem}
        </RightWrapper>
      </ContentWrapper>
    </FooterRowWrapper>
  );
};

const FooterRowItemWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 50%;

  .links {
    p {
      letter-spacing: 0;
      font-size: 12px;
      letter-spacing: 0;
    }
  }
`;

export const FooterRowItem = ({ title, html }) => {
  return (
    <FooterRowItemWrapper>
      <Text size={'SMALLTITLE'} text={title} />
      <div
        className={'links'}
        dangerouslySetInnerHTML={{
          __html: html.value
        }}
      />
    </FooterRowItemWrapper>
  );
};

const FooterRowContactsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-right: 40px;

  ${theme.below.lg} {
    padding-right: 0;
    margin-top: 15px;
  }

  .contacts {
    margin-top: 20px;
    a,
    p {
      letter-spacing: 0;
      text-align: left;
      font-weight: 400;
      text-decoration: none;
      text-transform: none !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px !important;
      color: #656565 !important;
      ${theme.below.lg} {
        font-size: 12px;
      }
    }

    a {
      letter-spacing: 0;
      font-weight: 500;
    }
  }
`;

export const FooterRowContacts = ({ title, text, html }) => {
  return (
    <FooterRowContactsWrapper>
      <Text size={'SMALLTITLE'} text={title} />
      <Text size={'TEXT'} text={text} />
      <div
        className={'contacts'}
        dangerouslySetInnerHTML={{
          __html: html.value
        }}
      />
    </FooterRowContactsWrapper>
  );
};

const FooterRowNewsLetterWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-left: 20px;
  ${theme.below.lg} {
    padding-left: 0;
    margin-top: 30px;
  }

  .text-component {
    p {
      margin-bottom: 10px;
    }
  }
`;

export const FooterRowNewsLetter = ({ title, text }) => {
  return (
    <FooterRowNewsLetterWrapper>
      <Text size={'SMALLTITLE'} text={title} />
      <Text size={'TEXT'} text={text} />
      <NewsletterField />
    </FooterRowNewsLetterWrapper>
  );
};

const SocialMediaLinksWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 50%;
  max-height: 100%;

  .social-media-links {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    a {
      height: 30px;
      width: 30px;
      margin: 5px 5px 5px 0;
    }
  }
`;

export const SocialMediaLinks = props => {
  const tle = setVariableFromProp(props.title);
  const l1Url = setVariableFromProp(props.link1Url);
  const l1Img = setVariableFromProp(props.link1Image);
  const l2Url = setVariableFromProp(props.link2Url);
  const l2Img = setVariableFromProp(props.link2Image);
  const l3Url = setVariableFromProp(props.link3Url);
  const l3Img = setVariableFromProp(props.link3Image);

  const imageSizes = [20, 50];

  return (
    <SocialMediaLinksWrapper>
      <Text size={'SMALLTITLE'} text={tle} />
      <div className={'social-media-links'}>
        {l1Url && l1Img && (
          <a href={l1Url} target={'_blank'} rel="noreferrer">
            <Image cover src={l1Img} aspect={'1:1'} sizes={imageSizes} />
          </a>
        )}
        {l2Url && l2Img && (
          <a href={l2Url} target={'_blank'} rel="noreferrer">
            <Image cover src={l2Img} aspect={'1:1'} sizes={imageSizes} />
          </a>
        )}
        {l3Url && l3Img && (
          <a href={l3Url} target={'_blank'} rel="noreferrer">
            <Image cover src={l3Img} aspect={'1:1'} sizes={imageSizes} />
          </a>
        )}
      </div>
    </SocialMediaLinksWrapper>
  );
};
