import { Helmet } from 'react-helmet-async';
import React from 'react';

export const FaviconSelector = ({ lightIconPath, darkIconPath }) => {
  const darkmode = useDarkMode();
  return (
    <Helmet>
      {darkmode ? (
        <link rel="shortcut icon" id="favicon" href={lightIconPath} />
      ) : (
        <link rel="shortcut icon" id="favicon" href={darkIconPath} />
      )}
    </Helmet>
  );
};

const useDarkMode = () => {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );
};
