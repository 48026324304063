import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { setVariableFromProp } from '../../utils/HelpFunctions';

const TextWrapper = styled('div')`
  background: rgba(0, 0, 0, 0);
  position: relative;
  h1,
  h3,
  p {
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.color};
    line-height: 1.5;
  }

  h1 {
    font-size: 40px;
    font-family: ${theme.fonts.secondary};
    ${theme.below.lg} {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 18px;
    font-family: ${theme.fonts.secondary};
    ${theme.below.lg} {
      font-size: 13px;
    }
  }

  p {
    font-size: 14px;
  }

  &.shadow {
    h1,
    h3,
    p {
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    }
  }

  &.custom-size {
    font-size: ${props => props.size};
  }
`;

export const Text = ({
  size,
  customSize,
  color = '#fff',
  shadow = false,
  text
}) => {
  const textSize = setVariableFromProp(size);
  const textCustomSize = setVariableFromProp(customSize);
  const textColor = setVariableFromProp(color);
  const textShadow = setVariableFromProp(shadow);
  const textString = setVariableFromProp(text);

  const textElementSelector = () => {
    switch (textSize) {
      case 'TITLE':
        return <h1>{textString}</h1>;
      case 'SMALLTITLE':
        return <h3>{textString}</h3>;
      case 'TEXT':
        return <p>{textString}</p>;
      default:
        return <p>{textString}</p>;
    }
  };

  return (
    <TextWrapper
      color={textColor}
      className={cx(
        textShadow ? 'shadow' : null,
        textCustomSize ? 'custom-size' : null,
        'text-component'
      )}
      size={textCustomSize ?? ''}
    >
      {textElementSelector()}
    </TextWrapper>
  );
};
