import React from 'react';
import CategoryMenuContainer from './CategoryMenuContainer';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';

const Nav = styled('nav')`
  width: 100%;
  height: 100%;
  align-self: center;
  a,
  li {
    font-family: ${theme.fonts.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    text-transform: uppercase;
    color: #333333;
  }
  button {
    background: none;
    color: #666;
  }

  .menu-container {
    height: 100%;
    margin-top: 0;
  }
`;

export function CategoryMenu({ data }) {
  return (
    <Nav>
      <div key={'menuContainerPosed'} className="menu-container">
        <CategoryMenuContainer queryData={data} />
      </div>
    </Nav>
  );
}
