import useFindify from '@findify/react-bundle';
import loadable from '@loadable/component';
import { styled } from 'linaria/react';
import qs from 'qs';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { FindifyTracker } from '../Findify/Findify';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import CategoryHeader from './CategoryHeader';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { contentEditorComponents } from '../ContentEditor/ContentEditorComponents';
import { theme } from '../Theme';

export const LoadableStandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableWindowedCategoryPage = loadable(
  () => import('./WindowedCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const Container = styled(MaxWidth)`
  padding: 0 0.75rem;
`;

const CategoryTextWrapper = styled('div')`
  background-color: #fbfbfb;
  text-align: center;
  padding: 40px;
  h1 {
    text-transform: uppercase;
    margin-bottom: 40px;
    position: relative;
    font-size: 16px;
    color: #333;
    &:after {
      height: 2px;
      width: 35px;
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
      background: ${theme.colors.gold};
      content: '';
    }
  }
  > div {
    ${theme.above.lg} {
      margin: auto;
      max-width: 50%;
    }
  }
  p {
    margin-bottom: 20px;
  }
  font-size: 12px;
  a {
    color: black;
  }
`;

const CategoryPage = props => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const category = props.category;
  const result = props.result;
  let campaigns = [];

  const searchObject = qs.parse(search, { ignoreQueryPrefix: true });
  const standardPagination = searchObject.standardPagination === 'true';

  const [container, isReady, hasError] = useFindify({
    type: 'smart-collection',
    history,
    config: {
      campaigns,
      slot: pathname.replace('/', '').toLowerCase()
    }
  });

  const getItemsWithPropNamed = (key, items) => {
    return items?.filter(item =>
      item?.properties?.find(prop => prop?.name === key && prop?.value?.value)
    );
  };

  const seoItems = getItemsWithPropNamed('seo', category?.data?.items);

  if (!hasError) {
    return (
      <>
        <Container>
          <FindifyTracker />
          {category ? (
            <>
              <CategoryHeader
                category={category}
                parents={result?.data?.route?.parents ?? null}
              />
            </>
          ) : (
            <CategoryHeaderLoadingState />
          )}

          <div ref={container} />

          {!isReady && <LoadingPage />}
        </Container>

        {/* Only shows SEO flaged components */}
        {isReady && seoItems?.length > 0 && (
          <CategoryTextWrapper>
            <ContentRenderer
              items={seoItems}
              components={contentEditorComponents}
            />
          </CategoryTextWrapper>
        )}
      </>
    );
  }
  if (standardPagination) {
    return <LoadableStandardCategoryPage {...props} />;
  } else {
    return <LoadableWindowedCategoryPage {...props} />;
  }
};

export default CategoryPage;
