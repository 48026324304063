import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  width: 100vw;
  background: ${props => props.backgroundcolor};
  text-align: center;
  padding: 60px 0;

  h3 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 1px;
    ${theme.below.lg} {
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 5px;
    }
  }
  h1 {
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
    line-height: 43px;
    letter-spacing: 1px;
    max-width: 35%;
    margin: auto;
    ${theme.below.lg} {
      max-width: 100%;
      font-size: 27px;
      line-height: 29px;
    }
  }
`;

export const JournalPush = ({ children, backgroundColor }) => {
  return (
    <Wrapper backgroundcolor={backgroundColor?.value}>
      <div>{children}</div>
    </Wrapper>
  );
};
