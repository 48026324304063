import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import { LoadableProductPage, LoadableContentPage } from './components/Shop';
import {
  LoadableStandardCategoryPage,
  LoadableWindowedCategoryPage
} from './components/CategoryPage/CategoryPage';
import { theme } from './components/Theme';
import CampaignBarQuery from './components/ContentEditor/CampaignBarQuery.gql';
import CategoryContentQuery from './components/ContentEditor/CategoryContentQuery.gql';
// import BrandsQuery from './components/ProductPage/BrandsQuery.gql';
const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'fernerjacobsen',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io/',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 }
      },
      {
        query: CampaignBarQuery,
        variables: { id: 166 }
      },
      {
        query: CategoryContentQuery,
        variables: { id: 154 }
      }
    ]
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  additionalGtagTrackingIds: ['AW-123456789', 'G-BE9L1X7PGV'],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: false,
  sentry: {
    clientDSN: process.env.FLIGHT_SENTRY_CLIENT_DSN,
    serverDSN: process.env.FLIGHT_SENTRY_SERVER_DSN,
    ignoreErrors: []
  },
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          NKR: {
            style: 'decimal',
            currency: 'NOK',
            maximumFractionDigits: 0,
            currencyDisplay: 'symbol'
          }
        }
      }
    }
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  openGraph: {
    disableDefaultProductData: false
  },
  trackers: [],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [LoadableProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [LoadableContentPage]
    }
  }
};
export default config;
